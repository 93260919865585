$primary: #00427a;
$secondary: #006699;

// @import "../node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import '~antd/dist/reset.css';


#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: #0050b3;
}

.removeButtonRight {
  right: 15px;
  top: 15px;
}

.site-layout .site-layout-background {
  background: #0050b3 !important;
}

.ant-menu .ant-menu-item-selected {
  background-color: #0050b3 !important;
  /*Overriden property*/
}


.ant-layout-footer {
  padding: 5px 50px;
  color: #001529;
  background-color: #2b2e3168;

}

.ant-table-thead .ant-table-cell {
  background-color: #0050b3;
  color: white;
}

.ant-table-thead th.ant-table-column-sort {
  background-color: #d0e5ff;
  color: black;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #d0e5ff;
  color: black;
}

.ant-notification {
  z-index: 100000 !important;
}

.ant-table-sticky-holder {
  top: 3rem !important;
}

.custom-top {
  // top: 4px !important;
  background-color: #f0f2f5;
}

.cursor-pointer {
  cursor: pointer;
}

// .beat {
//   width: 10px;
//   height: 30px;
//   margin: 4px;
//   background-color: #5cdd8b;
//   border-radius: 15px;
// }

// Login Screen
.login-container{
  background: url('assets/login_background.jpg') center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

/* Style the scrollbar for a specific div */
.custom-scroll-div::-webkit-scrollbar {
  width: 5px; /* Width of the vertical scrollbar */
  height: 5px; /* Height of the horizontal scrollbar */
}

.custom-scroll-div::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 5px; /* Roundness of the track (optional) */
}

.custom-scroll-div::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Roundness of the handle (optional) */
}

/* Handle on hover */
.custom-scroll-div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar handle on hover */
}

.chat-background {
  background: url("assets/chat-background.jpg");
  background-size: cover;
  background-position: center center;
}